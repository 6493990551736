import BaseService from './base-service';
import { Pharmacy } from '../../entities/pharmacy.entity';

class PharmaciesService extends BaseService {
  listPharmacies(): Promise<Pharmacy[]> {
    return this._apiClient.get('/api/rest/v1/pharmacies').then(response => response.data);
  }
}

export default PharmaciesService;
