import { UseQueryResult, useQuery } from 'react-query';
import useApiClient from './use-api-client';
import { Pharmacy } from '../entities/pharmacy.entity';
import PharmaciesService from '../api/services/pharmacies.service';

export const PHARMACIES_QUERY_KEY = 'pharmacies';

const usePharmaciesQuery = (): UseQueryResult<Pharmacy[]> => {
  const apiClient = useApiClient();

  return useQuery<Pharmacy[]>(
    [PHARMACIES_QUERY_KEY],
    () => new PharmaciesService(apiClient).listPharmacies(),
    {
      staleTime: Infinity,
    }
  );
};

export default usePharmaciesQuery;
